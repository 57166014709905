import API from './axiosConfig'

export default {
  display: {
    getConfig: displayId =>
      API.get('/display/getConfiguration', { params: { displayId } }),
    alert: (storeId, queueId) =>
      API.get('/display/alert', { params: { storeId, queueId } }),
  },
}
