import styled from 'styled-components'
import { colors } from './constants'
export const TillNumberWrapper = styled.div`
  position: absolute;
  top: 300px;
  left: 800px;
  width: 880px;
  height: 660px;
  background-color: ${props => props.color};
`
export const TillNumber = styled.h1`
  width: 880px;
  height: 660px;
  color: ${colors.white};
  font-size: 600px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  margin: 0;
  animation-name: blinker;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`
