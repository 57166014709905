import { notification } from "antd"

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}
export const feedbackNotification = (type, title, subtitle) => {
  return notification[type]({
    message: title,
    description: subtitle
  })
}
export const handleServerError = error => {
  if (error.response) {
    switch (error.response.status) {
      default:
        return feedbackNotification('error', 'Si è verificato un errore', "Riprova")
      }
    }
    return feedbackNotification('error', 'Si è verificato un errore', "Riprova")
}